
import { defineComponent } from 'vue'
import Button from "@/components/Button.vue"
import FlipCard from '@/components/FlipCard.vue'
import FlipCardNew from '@/components/FlipCardNew.vue'
import Counter from "@/components/Counter.vue"
import Dialog from "@/components/Dialog.vue"
import Toast from "@/components/Toast.vue"

// Utils
import utilities from "../utilities"

export default defineComponent({
  name: "AskCard",
  components: {
    Button,
    FlipCard,
    FlipCardNew,
    Counter,
    Dialog,
    Toast
  },
  data() {
    return {
      local: false,
      token: '',
      step: 1,
      stepClass: '',
      uploadButtontext: 'Subir PDF',
      loading: false,
      loadingText: 'Cargando...',
      cardPreview: false,
      showRemoveDialog: false,
      onConfirmRemove: Function as any,
      onCancelRemove: Function as any,
      currentFile: [] as any[any],
      shippingMethods: [] as any[any],
      pickUpPoints: [] as any[any],
      transferInfo: '',
      selectedShippingMethod: [] as any[any],
      selectedPickUpPoint: [] as any[any],
      basePrice: 0,
      subtotal: 0,
      shipping: 0,
      fields: [] as any[any],
      validated: false,
      paymentMethods: [
        {
          name: 'card',
          label: 'Tarjeta',
          icon: '/img/credit-card-solid.svg',
        },
        {
          name: 'transfer',
          label: 'Transferencia',
          icon: '/img/money-bill-transfer-solid.svg',
        },
        {
          name: 'mp',
          label: 'Mercado Pago',
          icon: '/img/mercado-pago.png',
        }
      ],
      // Lista de provincias argentinas
      provinces: [
        'BUENOS AIRES', 'CATAMARCA', 'CHACO', 'CHUBUT', 'CÓRDOBA', 
        'CORRIENTES', 'ENTRE RÍOS', 'FORMOSA', 'JUJUY', 'LA PAMPA', 
        'LA RIOJA', 'MENDOZA', 'MISIONES', 'NEUQUÉN', 'RÍO NEGRO', 
        'SALTA', 'SAN JUAN', 'SAN LUIS', 'SANTA CRUZ', 'SANTA FE', 
        'SANTIAGO DEL ESTERO', 'TIERRA DEL FUEGO', 'TUCUMÁN', 'CAPITAL FEDERAL'
      ],
      selectedProvince: null as string | null,
      cities: [] as any[],
      cityNames: [] as string[],
      selectedCity: null as any,
      postalCodes: [] as string[],
      selectedPostalCode: '',
      shippingPrice: 0,
      loadingLocalities: false,
      loadingCities: false,
      loadingPostalCodes: false,
      shippingQuoteInfo: {
        price: 0,
        estimatedDelivery: '',
        visible: false
      },
    }
  },
  async created() {
    //check auth and get token
    let checkAuth = await utilities.checkAuth('carrito') as any

    this.token = checkAuth.token as any

    //get shipment methods
    let shipmentMethods = await utilities.getItems('shipping_methods?fields=*,fields.*', this.token) as any
    this.shippingMethods = shipmentMethods

    //get pick up points
    let pickUpPoints = await utilities.getItems('shops?filter[pick_up_point]=1', this.token) as any
    this.pickUpPoints = pickUpPoints

    //get config
    let config = await utilities.getItems('config/1?fields=*', this.token) as any
    Promise.resolve(config).then((data) => {

      //transfer info
      this.transferInfo = data.transfer_info

      //base price for credential
      this.basePrice = data.credential_price

      //disable card payments state
      if (data.disable_card_payments) {
        this.paymentMethods.shift()
      }

    })


    //set fields ref table
    this.fields['pick_up_point'] = 'Punto de Retiro'
    this.fields['shipping_first_name'] = 'Nombre'
    this.fields['shipping_last_name'] = 'Apellido'
    this.fields['shipping_id_nbr'] = 'N° de Documento'
    this.fields['shipping_email'] = 'Email'
    this.fields['shipping_area_code'] = 'Codigo de Area'
    this.fields['shipping_phone'] = 'Teléfono'
    this.fields['shipping_street'] = 'Calle'
    this.fields['shipping_street_nbr'] = 'Número/Altura'
    this.fields['shipping_floor'] = 'Piso'
    this.fields['shipping_apt'] = 'Departamento'
    this.fields['shipping_zip_code'] = 'Código Postal'
    this.fields['shipping_city'] = 'Ciudad'
    this.fields['shipping_obs'] = 'Obervaciones'
    this.fields['shipping_ref'] = 'Referencia'

    //update cart total on init
    this.updateCart('items')
  },

  async mounted() {
    let valid = await (await utilities.isValidated()).isValid;
    this.validated = true;
  },
  methods: {
    setShippingMethodsPrice(item: any) {
      this.shippingMethods.forEach((shippingMethod: any, index: number) => {
        if (item.name && item.name.includes('Box') && shippingMethod.price_box > 0) {
          if (!this.shippingMethods[index].selectedPrice || shippingMethod.price_box > this.shippingMethods[index].selectedPrice) {
            this.shippingMethods[index].selectedPrice = shippingMethod.price_box
            this.$store.state.order.has_box = true
          }
        }
        else {
          if (!this.shippingMethods[index].selectedPrice || shippingMethod.price > this.shippingMethods[index].selectedPrice) {
            this.shippingMethods[index].selectedPrice = shippingMethod.price
            this.$store.state.order.has_box = false
          }
        }

        console.log(shippingMethod)
      })
    },
    updateQuantity: function (quantity: number, type: string, fileIndex: number) {
      if (type == 'items') {
        this.$store.state.order.items[fileIndex].quantity = quantity
      }
      else {
        this.$store.state.order.products[fileIndex].quantity = quantity
      }

      this.updateCart('items')
    },
    updateCart: function (type: string, item: any = 0) {

      // update items
      if (type == 'items') {
        this.subtotal = 0
        //credentials sum
        this.$store.state.order.items.forEach((item: any) => {
          this.setShippingMethodsPrice(item)
          this.subtotal += item.item_price * item.quantity
        });
        //products sums
        this.$store.state.order.products.forEach((item: any) => {
          var discount = item.item_discount != null ? item.item_discount : 0;
          console.log("descuento  " + discount);
          console.log("item price: " + item.item_price);
          console.log("item discount: " + item.item_price * discount / 100);
          console.log("item quantity: " + item.quantity)
          this.setShippingMethodsPrice(item);
          this.subtotal += (item.item_price - (item.item_price * (this.validated === true && this.$store.state.flags.descuentoVerificado === true ? discount + 5 : discount) / 100)) * item.quantity;
        });
      }

      // update shipment method
      if (type == 'shipping') {
        this.$store.state.order.shipping_method = item.id
        this.shipping = item.selectedPrice
      }

    },
    removeItem: function (type: string, index: number) {
      this.showRemoveDialog = true

      this.onConfirmRemove = () => {
        if (type == 'items') {
          this.$store.state.order.items.splice(index, 1)
        }
        else {
          this.$store.state.order.products.splice(index, 1)
        }
        this.updateCart('items')
        this.showRemoveDialog = false
      }

      this.onCancelRemove = () => {
        this.showRemoveDialog = false
      }
    },
    setShippingMethod(method: []) {
      //unset previous pick up point
      this.$store.state.order.pick_up_point = null
      this.selectedPickUpPoint = []

      this.selectedShippingMethod = method
      this.updateCart('shipping', method)
      
      // Reset locality and postal code data when changing shipping methods
      this.selectedProvince = null;
      this.selectedCity = null;
      this.selectedPostalCode = '';
      this.cities = [];
      this.postalCodes = [];
      
      // Reset shipping quote information
      this.shippingQuoteInfo.visible = false;
      
      this.nextStep(this.step)
    },
    addShippingData() {
      const form = this.$refs.form as any

      Array.from(form).forEach((input: any) => {
        this.$store.state.order[input.name] = input.value;
      });
    },
    // Update the getformattedAddress method to include province information
    getformattedAddress() {
      let address = ''
      address = this.$store.state.order.shipping_street + ' ' + this.$store.state.order.shipping_street_nbr

      if (this.$store.state.order.shipping_floor || this.$store.state.order.shipping_apt) {
        address += ', '
        if (this.$store.state.order.shipping_floor) {
          address += 'Piso: ' + this.$store.state.order.shipping_floor + ' '
        }

        if (this.$store.state.order.shipping_apt) {
          address += 'Dto: ' + this.$store.state.order.shipping_apt
        }
      }

      address += ', ' + this.$store.state.order.shipping_city
      
      if (this.$store.state.order.shipping_province) {
        address += ', ' + this.$store.state.order.shipping_province
      }
      
      address += ' (' + this.$store.state.order.shipping_zip_code + ')'

      return address
    },
    setPickUpPoint(id: number) {
      this.pickUpPoints.forEach((pickUpPoint: any[any]) => {
        if (pickUpPoint.id == id) {
          this.selectedPickUpPoint = pickUpPoint
          this.$store.state.order.pick_up_point = id
        }
      })
    },
    
    // Método para manejar el cambio de provincia
    async onProvinceChange(event: any) {
      const selectedProvince = event.target.value;
      this.selectedProvince = selectedProvince;
      
      // Resetear ciudad y código postal
      this.selectedCity = null;
      this.selectedPostalCode = '';
      this.cities = [];
      this.postalCodes = [];
      
      // Cargar localidades filtradas por provincia
      await this.fetchCitiesByProvince(selectedProvince);
    },
    
    // Modificar el método fetchCitiesByProvince para guardar todas las entradas sin eliminar duplicados
    async fetchCitiesByProvince(province: string) {
      try {
        this.loadingCities = true;
        const encodedProvince = encodeURIComponent(province);
        const response = await fetch(`https://apisqa.andreani.com/v1/localidades?provincia=${encodedProvince}`);
        const data = await response.json();
        
        // Guardar todos los datos originales sin eliminar duplicados
        this.cities = data;
        
        // Extraer nombres únicos de localidades para el dropdown
        const uniqueCityNames = new Set();
        data.forEach((item: any) => {
          uniqueCityNames.add(item.localidad);
        });
        
        // Convertir el Set a array
        this.cityNames = Array.from(uniqueCityNames) as string[];
      } catch (error) {
        console.error('Error fetching cities:', error);
      } finally {
        this.loadingCities = false;
      }
    },
    
    // Modificar el método onCityChange para recopilar todos los códigos postales
    async onCityChange(event: any) {
      const selectedCity = event.target.value;
      
      // Encontrar todas las entradas que coincidan con la localidad seleccionada
      const matchingCities = this.cities.filter(city => city.localidad === selectedCity);
      
      if (matchingCities.length > 0) {
        this.selectedCity = selectedCity;
        
        // Extraer todos los códigos postales de las localidades coincidentes
        this.postalCodes = [];
        matchingCities.forEach(city => {
          if (city.codigosPostales && city.codigosPostales.length > 0) {
            this.postalCodes.push(...city.codigosPostales);
          }
        });
        
        // Eliminar duplicados si los hubiera
        this.postalCodes = [...new Set(this.postalCodes)];
        
        // Resetear el código postal seleccionado
        this.selectedPostalCode = '';
        this.$store.state.order.shipping_zip_code = '';
      }
    },
  
    async onPostalCodeChange(event: any) {
      this.selectedPostalCode = event.target.value;
      this.$store.state.order.shipping_zip_code = this.selectedPostalCode;

      // Mostrar un estado de carga mientras se calcula el precio
      this.shippingQuoteInfo = {
        price: 0,
        estimatedDelivery: '',
        visible: true
      };
      
      // Agregar un indicador de carga
      const loadingToast = this.$refs.toast as any;
      loadingToast.show('Calculando costo de envío...');

      // Calculate shipping price
      try {
        const response = await fetch('http://localhost:8081/andreani/shipping-price', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            cpDestino: this.selectedPostalCode,
            contrato: "399999999",
            cliente: "CL123456",
            sucursalOrigen: "S001",
            valorDeclarado: "2000",
            volumen: "60000",
            kilos: "5",
            altoCm: "50",
            largoCm: "40",
            anchoCm: "30"
          }),
        });
        
        const data = await response.json();
        // Update the shipping price in the selected shipping method using tarifaConIva
        if (this.selectedShippingMethod && data.tarifaConIva) {
          this.selectedShippingMethod.selectedPrice = data.tarifaConIva;
          this.shipping = data.tarifaConIva;
          // Update the order with the new shipping price
          this.updateCart('shipping', this.selectedShippingMethod);
          
          // Update shipping quote information
          this.shippingQuoteInfo = {
            price: data.tarifaConIva,
            estimatedDelivery: '3-5 días hábiles', // This could come from the API if available
            visible: true
          };
          
          // Mostrar un mensaje de éxito
          loadingToast.show('¡Cotización de envío actualizada!');
        }
      } catch (error) {
        console.error('Error calculating shipping price:', error);
        // Mostrar un mensaje de error
        loadingToast.show('Error al calcular el costo de envío. Intente nuevamente.');
        
        // Restablecer la información de cotización
        this.shippingQuoteInfo.visible = false;
      }
    },
    setPaymentMethod(value: string) {
      this.$store.state.order.payment_method = value
    },
    async nextStep(step: number) {
      // step 1
      if (step === 1) {
        if (this.$store.state.order.items.length > 0 || this.$store.state.order.products.length > 0) {
          this.step++
        } else {
          (this.$refs.toast as any).show('No aregaste archivos')
        }
      }

      // step 2
      if (step === 2) {
        if (Object.keys(this.selectedShippingMethod).length > 0) {
          this.step++
        } else {
          (this.$refs.toast as any).show('No seleccionaste un método de envío')
        }
      }

      // step 3
      if (step === 3) {

        const form = this.$refs.form as FormData
        const formCheck = utilities.checkForm(form)

        if (formCheck) {
          this.addShippingData()
          this.step++
        } else {
          (this.$refs.toast as any).show('Hay datos incompletos')
        }

      }

      // step 4
      if (step === 4) {

        if (this.$store.state.order.payment_method) {
          this.step++

          //post order
          let createOrders = utilities.createOrder(this.$store.state.order, this.token) as any
          Promise.resolve(createOrders).then((order) => {
            this.step++

            if (order.payment_method == 'card') {

              let geyPayment = utilities.createPaymentHash(order) as any

              Promise.resolve(geyPayment).then((paymentData) => {
                setTimeout(() => {
                  window.location.replace(paymentData.url)
                }, 3000)
              })

            }
            if (order.payment_method == 'mp') {
              console.log(order);
              let getPaymentMP = utilities.paymentMP(
                order.shipping_first_name + " " + order.shipping_last_name, //nombre
                1, //quantity
                order.total, //total price
                order.shipping_track_id,
                order.id) as any

              Promise.resolve(getPaymentMP).then((paymentData) => {
                console.log(paymentData.result.init_point)
                setTimeout(() => {
                  window.location.replace(paymentData.result.init_point)
                }, 3000)
              })
            }
          })
        } else {
          (this.$refs.toast as any).show('Selecciona un método de pago')
        }

      }
    },
    // Agregar métodos para calcular el precio y descuento de productos
    calculateProductPrice(item: { item_price: number; item_discount: number | null; quantity: number }) {
      const discount = item.item_discount != null ? item.item_discount : 0;
      const additionalDiscount = this.validated === true && this.$store.state.flags.descuentoVerificado === true ? 5 : 0;
      const totalDiscount = discount + additionalDiscount;
      
      return (item.item_price - (item.item_price * totalDiscount / 100)) * item.quantity;
    },

    getDiscount(item: { item_discount: number | null }) {
      const discount = item.item_discount != null ? item.item_discount : 0;
      const additionalDiscount = this.validated === true && this.$store.state.flags.descuentoVerificado === true ? 5 : 0;
      
      return discount + additionalDiscount;
    }
  }
})
